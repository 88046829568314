











import Vue from 'vue'
import Component from 'vue-class-component'
// import StatementFiltersNew from "@/modules/statements/components/StatementFiltersNew.vue";
import StatementTable from "@/modules/statements/components/StatementTable.vue";
import {TableData} from "@/modules/genprox/modules/profile/store/types";
import {mapState} from "vuex";
import { StatementItem } from '@/modules/statements/store/types';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import StatementTransactionsTable from '@/modules/statements/components/StatementTransactionsTable.vue';
import StatementProductsTable from '@/modules/statements/components/StatementProductsTable.vue';

@Component({
  components: { /*StatementFiltersNew,*/ StatementTable, StatementTransactionsTable, StatementProductsTable, SygniRoundedButton, SygniLoader, SygniLinkButton },
  computed: mapState('statements', {
    statementsTable: (state: any) => state.statementsTable,
    isBusy: (state: any) => state.statementsTableIsBusy,
    perPage: (state: any) => state.statementsTable.perPage,
  })
})
export default class statementModule extends Vue {
  readonly supportedFileFormats: Array<string> = ['pdf', 'jpeg', 'jpg', 'png'];
  statementsTable!: TableData<StatementItem>;
  perPage!: number;
  isBusy!: boolean;
  filtersQueryString: string = '';
  isLoading: boolean = false;

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  applyFiltersEvent(queryString: string) {
    this.filtersQueryString = queryString;
    (this.$refs.statementTable as StatementTable).onFiltersChange(queryString);
  }
}
