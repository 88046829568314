var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "statement-module"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "statement-module__container"
  }, [_c('statement-products-table', {
    staticClass: "mb-5"
  }), _c('statement-transactions-table')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }